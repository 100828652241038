.spacer {
  aspect-ratio: 1500/400;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.waves-bottom {
  background-image: url('waves-bottom.svg');
}

.flip {
  transform: rotate(180deg);
}
